import React from "react";
import logo from "./assets/sa.jpg"; // Tell webpack this JS file uses this image
import "./app.css";

console.log(logo); // /logo.84287d09.png

function Header() {
  // Import result is the URL of your image
  return <div className="as"></div>;
}

export default Header;
